import React from 'react';

// Components
import Layout from '../../components/layout';
import Links from '../../components/gallery/links';

import routes from '../../constants/routes';

const GalleryPage = () => {
    return (
        <Layout slug={routes.gallery} title="Gallery">
            <Links />
        </Layout>
    );
};

export default GalleryPage;

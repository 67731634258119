import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from '../../styles/modules/gallery-links.module.scss';
import routes from '../../constants/routes';

const Links = () => {
    return (
        <div className={styles.galleryLinks}>
            <h1>Gallery</h1>
            <div className="copy-width">
                <div className={styles.grid}>
                    <div className={styles.gridItem}>
                        <Link to={routes.gallery_custom}>
                            <StaticImage alt="" src="../../../static/assets/custom.jpg" layout="fullWidth" aspectRatio={1} placeholder="blurred" />
                            <div className={styles.overlay}>Custom Homes</div>
                        </Link>
                    </div>
                    <div className={styles.gridItem}>
                        <Link to={routes.gallery_remodels}>
                            <StaticImage alt="" src="../../../static/assets/remodel.jpg" layout="fullWidth" aspectRatio={1} placeholder="blurred" />
                            <div className={styles.overlay}>Remodels</div>
                        </Link>
                    </div>
                    <div className={styles.gridItem}>
                        <Link to={routes.gallery_additions}>
                            <StaticImage alt="" src="../../../static/assets/img_20191112_171833-1-.jpg" layout="fullWidth" aspectRatio={1} placeholder="blurred" />
                            <div className={styles.overlay}>Additions</div>
                        </Link>
                    </div>
                    <div className={styles.gridItem}>
                        <Link to={routes.gallery_garages}>
                            <StaticImage alt="" src="../../../static/assets/20210314_110717.jpg" layout="fullWidth" aspectRatio={1} placeholder="blurred" />
                            <div className={styles.overlay}>Garages</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Links;
